// extracted by mini-css-extract-plugin
export var component = "Header-module--component--ueXjd";
export var wrapper = "Header-module--wrapper--1RbNj";
export var iconWrapper = "Header-module--icon-wrapper--1NTWf";
export var icon = "Header-module--icon--1jXci";
export var logo = "Header-module--logo--2nlr3";
export var dropdownTrigger = "Header-module--dropdownTrigger--2Wm7D";
export var dropdownIcon = "Header-module--dropdownIcon--3eFCi";
export var dropdown = "Header-module--dropdown--155b2";
export var dropdownList = "Header-module--dropdownList--1IhEw";
export var dropdownListItem = "Header-module--dropdownListItem--113Cx";
export var dropdownLink = "Header-module--dropdownLink--284v9";
export var dropdownOpen = "Header-module--dropdownOpen--XEOT-";