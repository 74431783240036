import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleMeta.module.css";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

ArticleMeta.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleMeta({ styles = defaultStyles }) {
  const { t } = useTranslation();
  let {
    contentNode: { dateGmt, modifiedGmt, managedBy },
  } = usePageContext();

  return (
    <>
      <div className={styles.row}>
        <span className={styles.label}>{t("publishedAt")}: </span>
        <Time
          date={dateGmt}
          format={{
            day: "numeric",
            month: "long",
            year: "numeric",
          }}
        />
      </div>
      <div className={styles.row}>
        <span className={styles.label}>{t("updatedAt")}: </span>
        <Time
          date={modifiedGmt}
          format={{
            day: "numeric",
            month: "long",
            year: "numeric",
          }}
        />
      </div>
      {!!managedBy?.managedBy && (
        <div className={styles.row}>
          <span className={styles.label}>{t("author")}: </span>
          {managedBy.managedBy}
        </div>
      )}
    </>
  );
}
