import clsx from "clsx";
import React from "react";

import logo from "../images/logo.svg";

import * as defaultStyles from "./Logo.module.css";

export default function Logo({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <span className={clsx(className, styles.component)} {...restProps}>
      <img src={logo} /> Insidan
    </span>
  );
}
