import { H } from "@jfrk/react-heading-levels";
import * as defaultStyles from "@municipio/gatsby-theme-intranet/src/components/DnDMenu/DnDMenu.module.css";
import DnDMenuDisplayView from "@municipio/gatsby-theme-intranet/src/components/DnDMenu/DnDMenuDisplayView";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { createContext } from "react";

DnDMenuContainer.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  showLessLabel: PropTypes.string,
  showMoreLabel: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.node,
  visibleItemCount: PropTypes.number,
};

export default function DnDMenuContainer({
  items = [],
  onChange,
  title,
  showMoreLabel,
  showLessLabel,
  styles = defaultStyles,
  visibleItemCount = 5,
  ...restProps
}) {
  return (
    <DnDContainerContext.Provider value={{ items, visibleItemCount, onChange }}>
      <div
        className={clsx(styles.component, utilities.hiddenPrint)}
        {...restProps}
      >
        <div className={clsx(styles.header)}>
          <H className={clsx(styles.label)}>{title}</H>
        </div>
        <DnDMenuDisplayView labels={{ showLessLabel, showMoreLabel }} />
      </div>
    </DnDContainerContext.Provider>
  );
}

export const DnDContainerContext = createContext();
