// extracted by mini-css-extract-plugin
export var component = "TopNavigation-module--component--QapPi";
export var list = "TopNavigation-module--list--1d4cF";
export var item = "TopNavigation-module--item--3PIPW";
export var label = "TopNavigation-module--label--1Vk6O";
export var text = "TopNavigation-module--text--I4RRB";
export var link = "TopNavigation-module--link--2Cw9u";
export var iconWrapper = "TopNavigation-module--icon-wrapper--mPII6";
export var transparent = "TopNavigation-module--transparent--5heJ1";
export var iconWrapperHouseIcon = "TopNavigation-module--icon-wrapper-house-icon--1E2Yo";
export var iconWrapperNewspaperIcon = "TopNavigation-module--icon-wrapper-newspaper-icon--2UcBk";
export var iconWrapperCalendarIcon = "TopNavigation-module--icon-wrapper-calendar-icon--22MKF";
export var iconWrapperPeopleIcon = "TopNavigation-module--icon-wrapper-people-icon--2dMrx";
export var icon = "TopNavigation-module--icon--2gkvZ";